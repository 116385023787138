import React, { useEffect, useState, useMemo } from "react";
import { IoArrowBack } from "react-icons/io5";
import Card from "../../components/Card/Card";
import PieChartView from "../../components/PieChartView/PieChartView";
import ShortStatBox from "../../components/ShortStatBox/ShortStatBox";
import ShowData from "../../components/ShowData/ShowData";
import "./dashboard.css";
import BarChart from "../../components/Chart/BarChart";
import Decrease from "../../components/CountChange/Decrease";
import Increase from "../../components/CountChange/Increase";
import BoxIcon from "../../components/BoxIcon/BoxIcon";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { AiOutlineHome } from "react-icons/ai";
import FilterBox from "../../components/FilterBox/FilterBox";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import ErrorPage from "../ErrorPage/ErrorPage";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

type API_data = {
    data: Array<{
        region: {
            name: String;
            code: String;
        };
        camps: number;
        counts: Array<number>;
    }>;
};

interface ImageArrays {
    [key: number]: string[];
}

export default function Dashboard({ togglemode, setPath }: any) {
    // States
    // Five years day - by default
    const currentDate = new Date();
    const pastDate = new Date("2016-08-06");

    // Get timestamps in milliseconds
    const currentTs = currentDate.getTime();
    const pastTs = pastDate.getTime();

    // Calculate difference in milliseconds
    const millisecondDiff = currentTs - pastTs;

    // Print result
    /* const images: string[] = [
        
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17664.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17660.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17684.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17676.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17657.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17649.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17683.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17687.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17688.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17690.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17662.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17648.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17674.jpg",
        "https://d1ivwsnbainlhe.cloudfront.net/msg-503625805-17690.jpg",

        // Add more image URLs as needed
    ]; */
    const imageArraysByYear = useMemo<ImageArrays>(
        () => ({
            2018: [
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181114_115809.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181114_120011.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181114_123928.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181114_124426.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181114_131835.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181125_105402.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181125_105414.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181125_112543.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181125_120751.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181125_131319.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181208_164754.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181208_164941.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181208_164959.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181209_195021.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181209_201507.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181209_201537.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/20181212_164526.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/Amit-Kumar-DAs4.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/DSC_6572.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/DSC_6598.JPG",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/DSC_6599.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/DSC_6633.JPG",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/DSC_6634.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/Golam-MOniruddin.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181114-WA0040.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181114-WA0043.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181114-WA0046.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181125-WA0029.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181125-WA0032.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181125-WA0042.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181128-WA0027.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181130-WA0027.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181130-WA0034.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181130-WA0036.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181130-WA0037.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181206-WA0053.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181206-WA0055.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181206-WA0060.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181208-WA0009.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/IMG-20181208-WA0019.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/Mohammad-Imran2.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/Mohammad-Jahangir-2.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/Munawar-Hussain.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/Screenshot_2024-02-21-11-53-07-46_965bbf4d18d205f782c6b8409c5773a4.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/Screenshot_2024-02-21-11-53-39-50_965bbf4d18d205f782c6b8409c5773a4.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/Screenshot_2024-02-21-11-54-25-14_965bbf4d18d205f782c6b8409c5773a4.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/Screenshot_2024-02-21-11-57-08-87_965bbf4d18d205f782c6b8409c5773a4.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2018/Screenshot_2024-02-21-11-57-31-16_965bbf4d18d205f782c6b8409c5773a4.jpg",
            ],
            2019: [
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190323_110453.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190323_110500.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190323_110600.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190323_110636.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190323_110917.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190323_110945.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190323_122708.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190326_115845.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190328_122235.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190330_115742.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190330_115751.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190330_115807.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190330_121736.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/20190330_165615.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190313-WA0016.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190313-WA0018.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190314-WA0007.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190314-WA0013.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190314-WA0014.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190314-WA0020.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190314-WA0027.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190323-WA0008.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190323-WA0010.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190323-WA0011.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190323-WA0012.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190323-WA0015.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190326-WA0012.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190326-WA0013.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190326-WA0014.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190326-WA0015.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190326-WA0017.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190326-WA0019.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190328-WA0049.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190328-WA0053.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190328-WA0054.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190329-WA0037.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190329-WA0039.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190329-WA0041.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190329-WA0043.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190329-WA0045.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190330-WA0017.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190330-WA0019.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190330-WA0020.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190330-WA0021.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190330-WA0022.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190330-WA0027.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190330-WA0028.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190330-WA0030.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190403-WA0014.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190503-WA0028.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190608-WA0009.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190608-WA0026.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190609-WA0016.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190609-WA0048.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190609-WA0050.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190609-WA0054.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190614-WA0010.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190614-WA0011.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190614-WA0019.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190619-WA0005.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190619-WA0008.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190619-WA0022.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190619-WA0026.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190620-WA0027.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190627-WA0014.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190627-WA0020.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190627-WA0033.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190705-WA0008.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190730-WA0013.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190730-WA0018.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190803-WA0001.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190807-WA0038.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190820-WA0009.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190825-WA0010.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20190929-WA0020.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191006-WA0008.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191006-WA0012.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191006-WA0017.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191006-WA0018.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191006-WA0024.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191008-WA0021.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191008-WA0024.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191024-WA0010.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191025-WA0015.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191025-WA0017.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191025-WA0028.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191025-WA0040.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191025-WA0042.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191025-WA0046.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191025-WA0052.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191101-WA0019.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191103-WA0035.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191103-WA0037.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191103-WA0046.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191103-WA0065.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191103-WA0067.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191104-WA0027.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191108-WA0022.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191114-WA0020.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191114-WA0035.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191204-WA0004.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191204-WA0012.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG-20191204-WA0015.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190414_110051.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190414_110201.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190414_110214.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190414_110222.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190414_110235.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190419_143111.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190501_123135.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190501_123304.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190501_123347.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190502_132431.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190513_134022.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190615_103658.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190615_103719.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190615_103954.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190615_104102.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190615_104118.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190615_104130.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190615_105824.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190624_100107.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190624_113525.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190624_121139.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190624_122610.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190624_123652.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190624_123700.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190713_104259.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190713_104302.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190713_112535.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190729_144403.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190809_104845.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190825_111954.JPG",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190825_120343.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190825_120424.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190825_123032.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190825_123043.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190825_123109.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190825_165647.JPG",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190913_120844.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190929_110304.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190929_125927.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190929_125931.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190929_130034.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190929_130036.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190929_130058.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190929_130100.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190929_130108.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20190929_130110.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20191019_182910.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20191019_190823.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20191101_141342.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20191101_153230.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20191101_153233.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20191101_161852.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20191101_161908.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20191104_140852.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20191107_174928.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/IMG_20191109_140048.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/Screenshot_20190719_192247.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.03.58.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.03-(1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.03-(2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.03-(3).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.03.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.04-(1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.04-(2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.04.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.05-(1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.05-(2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.05.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.06-(1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.06-(2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2019/WhatsApp-Image-2024-02-21-at-14.04.06.jpeg",
            ],
            2020: [
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20200131_140244.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20200207_141323.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20200207_153949.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20200213_154303.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20200213_154313.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20201106_122231.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20201106_122236.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20201106_122242.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20201106_122247.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20201106_122527.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20201106_122531.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20201106_124507.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20201109_111048.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20201109_121121.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/IMG_20201109_121142.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/WhatsApp Image 2024-02-21 at 14.04.07 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/WhatsApp Image 2024-02-21 at 14.04.07 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/WhatsApp Image 2024-02-21 at 14.04.07.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/WhatsApp Image 2024-02-21 at 14.04.08 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/WhatsApp Image 2024-02-21 at 14.04.08 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/WhatsApp Image 2024-02-21 at 14.04.08.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/WhatsApp Image 2024-02-21 at 14.04.09 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/WhatsApp Image 2024-02-21 at 14.04.09 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2020/WhatsApp Image 2024-02-21 at 14.04.09.jpeg",
            ],
            2021: [
                "https://d1ivwsnbainlhe.cloudfront.net/2021/FB_IMG_1630165578370.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/FB_IMG_1630165582101.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG-20211107-WA0032.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG-20211107-WA0033.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG-20211114-WA0036.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG-20211114-WA0037.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG-20211114-WA0038.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG-20211114-WA0039.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG-20211114-WA0045.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG-20211114-WA0048.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210103_141224.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210103_141338.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210103_141359.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210103_141422.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210103_141501.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210103_145610_Burst13.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210103_215701.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210103_215842.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_102815.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_102848.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_103121.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_103214.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_110625.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_111037.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_111307.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_114054.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_114554.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_120835.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_121154.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_125318.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_130126.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_132251.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_133723.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_134029.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210124_140043.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210801_103551.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_091340.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_103930.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_104843.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_105812.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_110308.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_112151.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_113631.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_113653.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_114330.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_122339.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_133558.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_133612.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_133739.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_133742.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_133839.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_133917.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_143533.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_143534.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_143535.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20210828_144143.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_122614.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_122701.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_123730.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_123746.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_123748.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_124840.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_124932.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_125956.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_130531.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_130535.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_135826.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/IMG_20211107_140914.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/Screenshot_2021_0827_130831.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2021/WhatsApp-Image-2024-02-21-at-14.04.10.jpeg",
            ],
            2022: [
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG20220602113449.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG20220605110434.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG20220608122900.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG20220608133906.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG20220608134006_01.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG20220608141026.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG20221223085331.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220324_115351.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220324_124835.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220324_125328.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220324_125736.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220324_125738.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220324_162823.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220328_132220.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220328_132224.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220328_132235.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220328_132511.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/IMG_20220328_132519.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.03.20 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.03.20 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.03.20 (3).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.03.20 (4).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.03.20 (5).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.03.20 (6).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.03.20 (7).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.03.20 (8).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.03.20.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (10).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (12).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (13).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (14).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (15).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (16).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (17).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (18).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (19).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (20).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (21).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (22).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (23).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (24).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (25).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (26).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (27).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (28).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (3).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (4).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (5).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (6).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (7).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (8).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47 (9).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2023-12-11 at 21.04.47.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.47.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.48.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.52 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.52 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.52 (3).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.52.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.53 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.53 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.53.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.55 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.55 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.55 (3).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.55.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.56 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.56 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.56 (3).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.56.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.57 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.57 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.57.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.58 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.58 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.58 (3).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.58.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.59 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.59 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.59 (3).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.01.59.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.02.00 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.02.00 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.02.00.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.03.57.jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.04.10 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.04.10 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.04.11 (1).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.04.11 (2).jpeg",
                "https://d1ivwsnbainlhe.cloudfront.net/2022/WhatsApp Image 2024-02-21 at 14.04.11.jpeg",
            ],

            2023: [
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230927141019.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230927141439.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230927142250.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230927143732.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230928121737.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230928121913.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230928123700.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230928123850.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230928130009.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230928134836.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230928141203.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230928163159.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230929122427_01.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230929123702.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230929124116.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20230929130246.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20231203140117.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20231226143021.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20231226143213.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20231226143217.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20231226143222.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20231226154634.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119103019.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119103902.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119105415.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119105435.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119105450.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119105539.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119105547.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119110241.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119111145.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119112055.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119125355.jpg",
                "https://d1ivwsnbainlhe.cloudfront.net/2023/IMG20240119125618.jpg",
            ],
2024: [
    "https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0004.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0005.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0006.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0007.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0008.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0009.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0010.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0011.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0012.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0013.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0014.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0015.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0016.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0017.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0019.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0020.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0022.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG-20240822-WA0024.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG_20240822_153055.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG_20240822_153455.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG_20240822_154357.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG_20240822_154800.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG_20240822_154923.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG_20240822_154932.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG_20240822_155352.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG_20240822_155908.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG_20240822_160301.jpg",
"https://d1ivwsnbainlhe.cloudfront.net/2024/IMG_20240822_165707.jpg",
],
        }),
        []
    );
    /* const imageArraysByYear: ImageArrays = {
        
    }; */

    const handleYearChange = (year: number) => {
        setSelectedYear(year);
    };

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    let day = millisecondDiff;
    const [chartorgdata, setChartOrgData] = useState<any>(null);
    const [chartData, setChartData] = useState<any>(null);
    const [dataShowType, setDataShowType] = useState<String>("Country");
    const [selectedDataType, setSelectedDataType] = useState<String>("India");
    const [selectState, setSelectState] = useState<String | null>(null);
    const [selectDistrict, setSelectDistrict] = useState<String | null>(null);
    const [selectCity, setSelectCity] = useState<String | null>(null);
    const [dateRange, setDateRange] = useState<any>([
        new Date(new Date().getTime() - day),
        new Date(),
    ]);
    const [filterbox, setFilterBox] = useState<String | null>("all_time");
    const [currData, setCurrData] = useState<API_data | any>({ data: [] });
    const [loading, setLoading] = useState<Boolean>(false);
    const [error, setError] = useState<any>(null);
    const [pageLoading, setPageLoading] = useState<Boolean>(true);
    const [showLoading, setShowLoading] = useState<Boolean>(true);
    const [totalCamps, setTotalCamps] = useState<any>(null);
    const baseApiUrl = process.env.REACT_APP_API as string;
    const apiUrl = `${baseApiUrl}/camps`;
    const [selectedYear, setSelectedYear] = useState<number>(2024);
    const [preloadedImages, setPreloadedImages] = useState<string[]>([]);

    useEffect(() => {
        // Preload images for the selected year
        const imagesToPreload = imageArraysByYear[selectedYear];
        const preloadedImagesArray: string[] = [];

        const loadImage = (src: string) => {
            const img = new Image();
            img.src = src;
            preloadedImagesArray.push(src);
        };

        imagesToPreload.forEach((image) => {
            loadImage(image);
        });

        setPreloadedImages(preloadedImagesArray);
    }, [selectedYear]);

    // Effects
    useEffect(() => {
        const syear = dateRange[0].toLocaleString("default", {
            year: "numeric",
        });
        const smonth = dateRange[0].toLocaleString("default", {
            month: "2-digit",
        });
        const sday = dateRange[0].toLocaleString("default", { day: "2-digit" });
        const startDate = syear + "-" + smonth + "-" + sday;

        const eyear = dateRange[1].toLocaleString("default", {
            year: "numeric",
        });
        const emonth = dateRange[1].toLocaleString("default", {
            month: "2-digit",
        });
        const eday = dateRange[1].toLocaleString("default", { day: "2-digit" });
        const endDate = eyear + "-" + emonth + "-" + eday;

        API_Call(
            startDate,
            endDate,
            selectedDataType,
            selectState,
            selectDistrict,
            selectCity
        );
    }, [
        selectedDataType,
        dataShowType,
        selectState,
        selectDistrict,
        selectCity,
        dateRange,
    ]);

    useEffect(() => {
        let day: number = 0;

        if (filterbox === "today") {
            day = 0 * 86400000;
        }
        if (filterbox === "one_day") {
            day = 1 * 86400000;
        }
        if (filterbox === "one_week") {
            day = 7 * 86400000;
        }
        if (filterbox === "one_month") {
            day = 30 * 86400000;
        }
        if (filterbox === "three_months") {
            day = 90 * 86400000;
        }
        if (filterbox === "six_months") {
            day = 180 * 86400000;
        }
        if (filterbox === "one_year") {
            day = 365 * 86400000;
        }
        if (filterbox === "five_years") {
            day = 365 * 5 * 86400000;
        }
        if (filterbox === "all_time") {
            // Set the start date to August 6th 2016
            setDateRange([new Date("2016-08-06"), new Date()]);
        } else {
            // Set the start date based on the day variable
            setDateRange([new Date(new Date().getTime() - day), new Date()]);
        }
    }, [filterbox]);

    useEffect(() => {
        if (chartData) {
            let datasets: any = [];
            chartData.meta.series.data.forEach((d: any, ind: number) => {
                datasets.push({
                    name: d,
                    data: chartData.series.data[ind],
                });
            });
            let chartdata = {
                labels: chartData.series.labels,
                datasets: datasets,
            };
            setChartOrgData(chartdata);
        }
    }, [chartData]);

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setPageLoading(false);
            }, 1000);
        }
    }, [loading]);
    // Functions

    const API_Call = (
        startDate: String,
        endDate: String,
        selectedDataType: any,
        selectState: any,
        selectDistrict: any,
        selectCity: any
    ) => {
        let url = process.env.REACT_APP_API;
        if (
            selectedDataType !== null &&
            selectState === null &&
            selectDistrict === null &&
            selectCity === null
        ) {
            url =
                url +
                `/data/counts?country=India&startDate=${startDate}&endDate=${endDate}&level=State`;
        } else if (
            selectedDataType !== null &&
            selectState !== null &&
            selectDistrict === null &&
            selectCity === null
        ) {
            url =
                url +
                `/data/counts?country=India&state=${selectState}&startDate=${startDate}&endDate=${endDate}&level=District`;
        } else if (
            selectedDataType !== null &&
            selectState !== null &&
            selectDistrict !== null &&
            selectCity === null
        ) {
            url =
                url +
                `/data/counts?country=India&state=${selectState}&district=${selectDistrict}&startDate=${startDate}&endDate=${endDate}&level=City`;
        } else {
            url =
                url +
                `/data/counts?country=India&state=${selectState}&district=${selectDistrict}&city=${selectCity}&startDate=${startDate}&endDate=${endDate}`;
        }
        if (url !== undefined) {
            setLoading(showLoading);
            // setLoading(true);
            axios
                .get(url)
                .then(function (response) {
                    // handle success
                    setCurrData(response.data.result);
                    setChartData(response.data.result);
                    setLoading(false);
                })
                .catch(function (error) {
                    // handle error
                    setError(error);
                });
        }
    };
    useEffect(() => {
        // set the loading state to true before making the API call
        setLoading(true);
        axios
            .get(apiUrl)
            .then((response) => {
                // update the state with the data
                setTotalCamps(response?.data?.result?.camps.length);
                // set the loading state to false after getting the response
                setLoading(false);
            })
            .catch(function (error) {
                // handle error
                setError(error);
            });
    }, []);

    useEffect(() => {
        // Function to be executed every 30 seconds
        const poolingFun = () => {
            //console.log("running...");
            let day: number = 0;

            if (filterbox === "today") {
                day = 0 * 86400000;
            } else if (filterbox === "one_day") {
                day = 1 * 86400000;
            } else if (filterbox === "one_week") {
                day = 7 * 86400000;
            } else if (filterbox === "one_month") {
                day = 30 * 86400000;
            } else if (filterbox === "three_months") {
                day = 90 * 86400000;
            } else if (filterbox === "six_months") {
                day = 180 * 86400000;
            } else if (filterbox === "one_year") {
                day = 365 * 86400000;
            } else if (filterbox === "five_years") {
                day = 365 * 5 * 86400000;
            } else if (filterbox === "all_time") {
                // Set the start date to August 6th 2016
                setDateRange([new Date("2016-08-06"), new Date()]);
            } else {
                // Set the start date based on the day variable
                setShowLoading(false);
                setDateRange([
                    new Date(new Date().getTime() - day),
                    new Date(),
                ]);
            }
        };

        // Set up the interval to call poolingFun every 30 seconds
        const intervalId = setInterval(poolingFun, 30000);
        setShowLoading(true);
        return () => clearInterval(intervalId);
    }, [filterbox]);

    const setBack = () => {
        if (dataShowType === "State") {
            setDataShowType("Country");
            setSelectedDataType("India");
            setSelectState(null);
        }
        if (dataShowType === "District") {
            setDataShowType("State");
            setSelectedDataType(selectState ?? "State");
            setSelectDistrict(null);
        }
        if (dataShowType === "City") {
            setDataShowType("District");
            setSelectedDataType(selectDistrict ?? "District");
            setSelectCity(null);
        }
    };
    const setFilter = (e: any) => {
        let tag = e.target.tagName;
        let classname;
        if (tag === "svg" || tag === "SPAN") {
            classname = e.target.parentElement.className;
        } else {
            classname = e.target.className;
        }
        if (classname !== filterbox + " active") {
            setFilterBox(classname);
        }
        classname = classname.split(" active")[0];
        if (
            classname === "one_week" ||
            classname === "one_month" ||
            classname === "three_months" ||
            classname === "six_months" ||
            classname === "one_year" ||
            classname === "five_years" ||
            classname === "one_day" ||
            classname === "today" ||
            classname === "all_time"
        ) {
            setFilterBox(classname);
        }
    };
    if (error) {
        return <ErrorPage error={error?.message ?? "Error"} />;
    }
    if (pageLoading) {
        return (
            <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ height: "calc(100vh - 10.5rem)" }}
            >
                <Loader />
                <p className="mt-3">Loading... Wait for the data</p>
            </div>
        );
    }

    return (
        <div className="dashboard m-0 mt-4">
            <div className="dashboard_header">
                <div className="growth px-4 mx-1 mb-4 d-flex gap-2 justify-content-between flex-wrap align-items-center">
                    <div className="dash_filter">
                        <FilterBox
                            filterbox={filterbox}
                            setFilter={setFilter}
                        />
                    </div>
                    <div
                        data-tooltip-content="Select date range"
                        className="range"
                    >
                        <DateRangePicker
                            className="daterange"
                            disableCalendar={false}
                            value={dateRange}
                            clearIcon={null}
                            onChange={setDateRange}
                            calendarIcon={null}
                        />
                    </div>
                    <Tooltip anchorSelect=".range" />
                </div>
            </div>
            <div className="row m-auto boxes gap-0">
                <div className="col-xl-3 col-md-6 my-2 px-lg-2 px-2">
                    <div className="data_box">
                        <div className="d-flex gap-2 align-items-center">
                            <BoxIcon
                                color={"#ff0000"}
                                icon={
                                    <i
                                        className="fa fa-user"
                                        aria-hidden="true"
                                    ></i>
                                }
                            />
                            <p>Total Patients Screened</p>
                        </div>
                        <div className="d-flex mt-1 justify-content-between align-items-end">
                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                    <h2>
                                        {currData?.data.length !== 0
                                            ? currData?.data[0].counts[0]
                                            : 0}
                                    </h2>
                                    {/* {currData?.data[0]?.percentage.counts[0] <
                                    0 ? (
                                        <Decrease
                                            data={
                                                currData?.data.length !== 0
                                                    ? currData?.data[0]
                                                          ?.percentage.counts[0]
                                                    : 0
                                            }
                                        />
                                    ) : currData?.data[0]?.percentage
                                          .counts[0] > 0 ? (
                                        <Increase
                                            data={
                                                currData?.data.length !== 0
                                                    ? currData?.data[0]
                                                          ?.percentage.counts[0]
                                                    : 0
                                            }
                                        />
                                    ) : null} */}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 my-2 px-lg-2 px-2">
                    <div className="data_box">
                        <div className="d-flex gap-2 align-items-center">
                            <BoxIcon
                                color={"#28ce93"}
                                icon={
                                    <i
                                        className="fa fa-map-marker"
                                        aria-hidden="true"
                                    ></i>
                                }
                            />
                            <p>Total Camps</p>
                        </div>
                        <div className="d-flex mt-1 justify-content-between align-items-end">
                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                    <h2>
                                        {currData?.data.length !== 0
                                            ? currData?.data[0].camps
                                            : 0}
                                    </h2>
                                    {currData?.data[0]?.percentage.camps < 0 ? (
                                        <Decrease
                                            data={
                                                currData?.data.length !== 0
                                                    ? currData?.data[0]
                                                          ?.percentage.camps
                                                    : 0
                                            }
                                        />
                                    ) : currData?.data[0]?.percentage.camps >
                                      0 ? (
                                        <Increase
                                            data={
                                                currData?.data.length !== 0
                                                    ? currData?.data[0]
                                                          ?.percentage.camps
                                                    : 0
                                            }
                                        />
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 my-2 px-lg-2 px-2">
                    <div className="data_box">
                        <div className="d-flex gap-2 align-items-center">
                            <BoxIcon
                                color={"#017ec1"}
                                icon={
                                    <i
                                        className="fa fa-map-marker"
                                        aria-hidden="true"
                                    ></i>
                                }
                            />
                            <p>Total DR Positive</p>
                        </div>
                        <div className="d-flex mt-1 justify-content-between align-items-end">
                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                    <h2>
                                        {currData?.data.length !== 0
                                            ? currData?.data[0].counts[1]
                                            : 0}
                                    </h2>
                                    {currData?.data[0]?.percentage.counts[1] <
                                    0 ? (
                                        <Decrease
                                            data={
                                                currData?.data.length !== 0
                                                    ? currData?.data[0]
                                                          ?.percentage.counts[1]
                                                    : 0
                                            }
                                        />
                                    ) : currData?.data[0]?.percentage
                                          .counts[1] > 0 ? (
                                        <Increase
                                            data={
                                                currData?.data.length !== 0
                                                    ? currData?.data[0]
                                                          ?.percentage.counts[1]
                                                    : 0
                                            }
                                        />
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className="col-xl-3 col-md-6 my-2 px-lg-2 px-2">
          <div className="data_box">
            <div className="d-flex gap-2 align-items-center">
              <BoxIcon color={"#8874d1"} icon={<i className="fa fa-user" aria-hidden="true"></i>}/>
              <p>Total Gradable</p>
            </div>
            <div className='d-flex mt-1 justify-content-between align-items-end'>
              {loading?<Loader />:
              <>
                <h2>{currData?.data.length!==0?currData?.data[0].counts[2]:0}</h2>
                {currData?.data[0]?.percentage.counts[2]<0?<Decrease data={currData?.data.length!==0?currData?.data[0]?.percentage.counts[2]:0} />:currData?.data[0]?.percentage.counts[2]>0?<Increase data={currData?.data.length!==0?currData?.data[0]?.percentage.counts[2]:0} />:null}
                
              </>
              }
            </div>  
          </div>
        </div> */}
            </div>
            <div className="chart_table row  m-aut mb-0 mx-2 px-2 gap-lg-2">
                <div className="chart col-5 px-0 px-lg-2 my-2">
                    <div
                        className={
                            togglemode === "1" ? "country" : "country light"
                        }
                    >
                        <div className="state-selection" style={{ margin: 0 }}>
                            {chartorgdata && chartData ? (
                                <div
                                    className="locationchart dash_bar my-0"
                                    style={{ height: "auto" }}
                                >
                                    <p className="px-3 mt-3">
                                        Bar visualization of {selectedDataType}
                                    </p>
                                    <BarChart
                                        loading={loading}
                                        chartData={chartorgdata}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="cust_table p-0 col-7 ps-lg-1 my-2">
                    <div
                        className={
                            togglemode === "1"
                                ? "statedisstat mx-0 w-100 m-0 h-100"
                                : "statedisstat light mx-0 w-100 m-0 h-100"
                        }
                    >
                        <div className="statehead dash_head">
                            {dataShowType !== "Country" ? (
                                <IoArrowBack
                                    data-tooltip-content="Back"
                                    className="list_box"
                                    style={{ cursor: "pointer" }}
                                    onClick={setBack}
                                />
                            ) : (
                                <AiOutlineHome
                                    data-tooltip-content="Home"
                                    className="list_box"
                                    style={{ cursor: "pointer" }}
                                    onClick={setBack}
                                />
                            )}
                            <p>{selectedDataType}</p>
                            <Tooltip anchorSelect=".list_box" />
                        </div>
                        <div className="statedist">
                            <ShowData
                                togglemode={togglemode}
                                dataShowType={dataShowType}
                                selectedDataType={selectedDataType}
                                setDataShowType={setDataShowType}
                                selectState={selectState}
                                setSelectState={setSelectState}
                                selectDistrict={selectDistrict}
                                setSelectDistrict={setSelectDistrict}
                                setSelectedDataType={setSelectedDataType}
                                selectCity={selectCity}
                                setSelectCity={setSelectCity}
                                dateRange={dateRange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: "center" }}>
                {[2018, 2019, 2020, 2021, 2022, 2023, 2024].map((year) => (
                    <button
                        key={year}
                        onClick={() => handleYearChange(year)}
                        style={{
                            margin: "5px",
                            padding: "8px",
                            backgroundColor:
                                selectedYear === year ? "#3498db" : "#fff",
                            color: selectedYear === year ? "#fff" : "#000",
                            fontWeight:
                                selectedYear === year ? "bold" : "normal",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                    >
                        {year}
                    </button>
                ))}
            </div>
            <div
                style={{
                    marginLeft: "20px",
                    marginTop: "10px",
                    marginRight: "20px",
                }}
            >
                <Carousel
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    customTransition="all .5s ease-in-out"
                >
                    {preloadedImages?.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Image ${index + 1}`}
                            className="carousel-image"
                        />
                    ))}
                </Carousel>
            </div>

            <div className="all_stats  row  m-auto my-4 py-0 gap-0 mt-0 px-0 px-lg-3">
                {/* <div className="col-4 dash_pie my-2 px-2 px-lg-2">
                    <PieChartView data={currData?.data[0]} loading={loading} />
                </div> */}
                {/* <div className="col-4 dash_stat my-2 px-2 px-lg-2">
                    <ShortStatBox data={currData?.data[0]} loading={loading} />
                </div> */}
                {/* <div className="col-4 dash_card my-2 px-lg-2 px-3 mt-3">
                    <Card />
                </div> */}
            </div>
        </div>
    );
}
